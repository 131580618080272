import * as d3 from "d3";
// import d3TestData1 from "../data/d3TestData1.csv";
// const d3TestData1 = "../data/d3TestData1.csv";

function d3Test1(parentTag) {
  let canvas = d3
    .select(parentTag)
    .append("svg")
    .attr("width", 500)
    .attr("height", 500);
  let circle = canvas
    .append("circle")
    .attr("r", 100)
    .attr("cx", 250)
    .attr("cy", 250)
    .attr("fill", "#EF4")
    .attr("stroke", "red")
    .attr("stroke-width", 10);
  let line1 = canvas
    .append("line")
    .attr("x1", 100)
    .attr("y1", 100)
    .attr("x2", 400)
    .attr("y2", 400)
    .attr("stroke", "black")
    .attr("stroke-width", 10);
  let line2 = canvas
    .append("line")
    .attr("x1", 400)
    .attr("y1", 100)
    .attr("x2", 100)
    .attr("y2", 400)
    .attr("stroke", "black")
    .attr("stroke-width", 10);
  let rect = canvas
    .append("rect")
    .attr("x", 100)
    .attr("y", 100)
    .attr("height", 300)
    .attr("width", 300)
    .attr("fill", "none")
    .attr("stroke", "green")
    .attr("stroke-width", 10);
  console.log(circle, line1, line2, rect);
}

function d3Test2(parentTag) {
  let canvas = d3
    .select(parentTag)
    .append("svg")
    .attr("width", 500)
    .attr("height", 500);

  let width = +canvas.attr("width");
  let height = +canvas.attr("height");

  const g = canvas
    .append("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`);

  let circle = g.append("circle");
  circle
    .attr("r", height / 2 - 1)
    .attr("fill", "#EF4")
    .attr("stroke", "red")
    .attr("stroke-width", 2);

  const eyeXOffset = 100;
  const eyeYOffset = 80;
  const eyeRadius = height / 15;
  const eyebrowWidth = 60;
  const eyebrowHeight = 10;

  const eyeG = g
    .append("g")
    .attr("transform", `translate(0, ${-eyeYOffset})`)
    .attr("fill", "balck");

  let leftEye = eyeG.append("circle");
  leftEye.attr("r", eyeRadius).attr("cx", +eyeXOffset);

  let rightEye = eyeG.append("circle");
  rightEye.attr("r", eyeRadius).attr("cx", -eyeXOffset);

  const eyebrowG = eyeG
    .append("g")
    .attr("transform", `translate(0, ${-eyeRadius - 20 - eyebrowHeight / 2})`);

  const leftEybrow = eyebrowG.append("rect");
  leftEybrow
    .attr("width", eyebrowWidth)
    .attr("height", eyebrowHeight)
    .attr("x", -eyeXOffset - eyebrowWidth / 2);

  const rightEybrow = eyebrowG.append("rect");
  rightEybrow
    .attr("width", eyebrowWidth)
    .attr("height", eyebrowHeight)
    .attr("x", +eyeXOffset - eyebrowWidth / 2);

  const arc = d3.arc();
  const smile = g.append("path");
  smile.attr(
    "d",
    arc({
      innerRadius: 180,
      outerRadius: 200,
      startAngle: (7 * Math.PI) / 12,
      endAngle: (17 * Math.PI) / 12
    })
  );
  repeat();
  function repeat() {
    eyebrowG
      .transition()
      .duration(2000)
      .attr(
        "transform",
        `translate(0, ${-eyeRadius - 20 - eyebrowHeight / 2 - 50})`
      )
      //nextTransition
      .transition()
      .duration(2000)
      .attr(
        "transform",
        `translate(0, ${-eyeRadius - 20 - eyebrowHeight / 2})`
      );

    leftEye
      .transition()
      .duration(2000)
      .attr("r", eyeRadius + 10)
      .transition()
      .duration(2000)
      .attr("r", eyeRadius);

    rightEye
      .transition()
      .duration(2000)
      .attr("r", eyeRadius + 10)
      .transition()
      .duration(2000)
      .attr("r", eyeRadius);

    smile
      .transition()
      .duration(2000)
      .attr(
        "d",
        arc({
          innerRadius: 180,
          outerRadius: 200,
          startAngle: (10 * Math.PI) / 12,
          endAngle: (14 * Math.PI) / 12
        })
      )
      //back
      .transition()
      .duration(2000)
      .attr(
        "d",
        arc({
          innerRadius: 180,
          outerRadius: 200,
          startAngle: (7 * Math.PI) / 12,
          endAngle: (17 * Math.PI) / 12
        })
      )
      .on("end", repeat);
  }
}

function d3Test3(parentTag) {
  const canvas = d3
    .select(parentTag)
    .append("svg")
    .attr("width", 900)
    .attr("height", 500)
    .style("background-color", "lightgrey");

  const width = +canvas.attr("width");
  const height = +canvas.attr("height");

  const render = data => {
    const xValue = d => d.population;
    const yValue = d => d.country;
    const margin = { top: 40, right: 20, bottom: 60, left: 110 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, xValue)])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(yValue))
      .range([0, innerHeight])
      .padding(0.1);

    const g = canvas
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const xAxisTickFormat = number =>
      d3
        .format(".3s")(number)
        .replace("G", "B");
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(xAxisTickFormat)
      .tickSize(-innerHeight);

    g.append("g")
      .call(d3.axisLeft(yScale))
      .selectAll(".domain, .tick line")
      .remove();

    const xAxisG = g
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${innerHeight})`);

    xAxisG.selectAll(".domain").remove();

    xAxisG
      .append("text")
      .attr("class", "xAxis-lable")
      .attr("y", 50)
      .attr("x", innerWidth / 2)
      .attr("fill", "black")
      .text("Population");

    g.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("y", d => yScale(yValue(d)))
      .attr("width", d => xScale(xValue(d)))
      .attr("height", yScale.bandwidth())
      .attr("fill", "steelblue");

    g.append("text")
      .attr("y", -10)
      .text("World Population chart for most popular countries");
  };

  d3.csv("/data/d3TestData1.csv").then(data => {
    data.forEach(d => {
      d.population = +d.population * 1000;
    });
    render(data);
  });
}

function d3Test4(parentTag) {
  // const canvas = d3
  //   .select(parentTag)
  //   .append("svg")
  //   .attr("width", 900)
  //   .attr("height", 500)
  //   .style("background-color", "lightgrey");

  // const width = +canvas.attr("width");
  // const height = +canvas.attr("height");

  const radius = 3;
  const height = 1000;
  const width = 1000;
  const step = radius * 2;
  const theta = Math.PI * (3 - Math.sqrt(5));

  var data = Array.from({ length: 2000 }, (_, i) => {
    const r = step * Math.sqrt((i += 0.5)),
      a = theta * i;
    return [width / 2 + r * Math.cos(a), height / 2 + r * Math.sin(a)];
  });

  let currentTransform = [width / 2, height / 2, height];

  const svg = d3
    .select(parentTag)
    .append("svg")
    .attr("viewBox", [0, 0, width, height])
    .attr("width", 900)
    .attr("height", 500);

  const g = svg.append("g");

  g.selectAll("circle")
    .data(data)
    .join("circle")
    .attr("cx", ([x]) => x)
    .attr("cy", ([, y]) => y)
    .attr("r", radius)
    .attr("fill", (d, i) => d3.interpolateRainbow(i / 360));

  function transition() {
    const d = data[Math.floor(Math.random() * data.length)];
    const i = d3.interpolateZoom(currentTransform, [...d, radius * 2 + 1]);

    g.transition()
      .delay(250)
      .duration(i.duration)
      .attrTween("transform", () => t => transform((currentTransform = i(t))))
      .on("end", transition);
  }

  function transform([x, y, r]) {
    return `
        translate(${width / 2}, ${height / 2})
        scale(${height / r})
        translate(${-x}, ${-y})
      `;
  }

  return svg.call(transition).node();
}

function makeNull(parentTag) {
  d3.select(parentTag).html(null);
}

export default { d3Test1, d3Test2, d3Test3, d3Test4, makeNull };
