var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hello"},[_c('img',{staticClass:"mascot",attrs:{"draggable":"false","src":require("@/assets/BrainyBaba.svg")}}),_c('h1',[_vm._v(_vm._s(_vm.msg))]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('h3',[_vm._v("Improve Addition")]),_vm._m(4),_c('h3',[_vm._v("Improve Squares")]),_vm._m(5),_c('h3',[_vm._v("Data Visualisation")]),_vm._m(6),_c('h3',[_vm._v("SVG Samples")]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" This is completely experimental project "),_c('br'),_c('br'),_vm._v("Check out the pages below with links: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"target":"_blank","href":"https://weather.brainybaba.com"}},[_vm._v("weather.brainybaba.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"target":"_blank","href":"https://sql-runner.brainybaba.com/"}},[_vm._v("sql-runner.brainybaba.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"target":"_blank","href":"https://spider.brainybaba.com/"}},[_vm._v("spider.brainybaba.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/addition/10x10","rel":"noopener"}},[_vm._v("10 x 10")])]),_c('li',[_c('a',{attrs:{"href":"/addition/addGame","rel":"noopener"}},[_vm._v("Gamification")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/squares/squareGame","rel":"noopener"}},[_vm._v("Square Game")])]),_c('li',[_c('a',{attrs:{"href":"/squares/squareInputGame","rel":"noopener"}},[_vm._v("Square Input")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/dataVisualisation"}},[_vm._v("Visualisations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/svgTrials"}},[_vm._v("svg Trials")])]),_c('li',[_c('a',{attrs:{"href":"/svgAnimate"}},[_vm._v("svg Animated")])])])
}]

export { render, staticRenderFns }