<template>
  <div class="menu">
    <div class="navBar">
      <div class="nav-logo pull-left">
        <img
          draggable="false"
          class="mascot"
          src="@/assets/BrainyBaba.svg"
        >
        <span class="nav-logo-text logo-text">Brainy Baba</span>
      </div>
    </div>
    <!-- <div
      class="leftBar pull-left"
      :class="!menuClicked ? 'collapsed' : 'wide'"
    >
      <div class="icon">
        <i class="icon-graphic fa fa-plus" />
        <span
          v-show="showText"
          class="icon-text"
        >Addition</span>
      </div>
      <hr>
      <div class="icon">
        <span class="icon-graphic"><strong>n</strong><sup>2</sup></span>
        <span
          v-show="showText"
          class="icon-text"
        >Squares</span>
      </div>
      <div
        class="icon-bottom icon"
        @click="isMenuClicked(!menuClicked)"
      >
        <i
          v-if="!menuClicked"
          class="icon-graphic fa fa-angle-double-right fa-lg"
        />
        <span v-if="menuClicked">
          <i class="icon-graphic fa fa-angle-double-left fa-lg" />
          <span
            v-show="showText"
            class="icon-text"
          >Collapse sidebar</span>
        </span>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "NavBar",
  data() {
    return {
      menuClicked: false,
      leftHover: false,
      showText: false,
      timeDelay: 50
    };
  },
  methods: {
    isMenuClicked(value) {
      this.menuClicked = value;
      if (value) {
        setTimeout(() => {
          this.showText = value;
        }, this.timeDelay);
      } else {
        this.showText = value;
      }
    },
    isLeftIn(value) {
      this.leftHover = value;
      if (value) {
        setTimeout(() => {
          this.showText = value;
        }, this.timeDelay);
      } else {
        this.showText = value;
      }
    }
  }
};
</script>
<style scoped>
.navBar {
  position: fixed;
  height: 65px;
  width: 100vw;
  background-color: #313131;
  color: white;
  top: 0;
  box-shadow: 0px 1px 5px #8a8989;
  z-index: 1000;
}
.navBar .icon {
  display: inline-block;
  width: 72px;
  margin-top: 20px;
}
.navBar .nav-logo {
  min-width: 250px;
  padding-left: 20px;
  margin-top: 5px;
  display: inline-block;
}
.navBar .nav-logo img {
  max-width: 50px;
}
.navBar .nav-logo-text {
  font-size: 1.5em;
  margin-left: 10px;
}

.leftBar {
  height: 100vh;
  position: fixed;
  top: 65px;
  z-index: 900;
  box-shadow: 1px 0px 5px #d4d3d3;
  background-color: #f9f9f9;
}
.leftBar .icon {
  text-align: left;
  padding: 20px 14px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}
.leftBar .icon .icon-graphic {
  margin-right: 10px;
  cursor: pointer;
}
.leftBar .icon:hover {
  background-color: #42b983;
}
.collapsed {
  width: 50px;
  color: #313131;
  transition: 50ms ease-out;
}
.wide {
  width: 187px;
  color: #313131;
  transition: 50ms ease-in;
}
hr {
  color: white;
  border: 0.5px #dddddd solid;
  margin: 0px 2px;
  opacity: 0.4;
}
.icon-text {
  position: absolute;
  cursor: pointer;
}
.leftBar .icon-bottom {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
