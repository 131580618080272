<template>
  <div class="hello">
    <img
      draggable="false"
      class="mascot"
      src="@/assets/BrainyBaba.svg"
    >
    <h1>{{ msg }}</h1>
    <p>
      This is completely experimental project
      <br><br>Check out the pages below with links:
    </p>
    <h3>
      <a
        target="_blank"
        href="https://weather.brainybaba.com"
      >weather.brainybaba.com</a>
    </h3>
    <h3>
      <a
        target="_blank"
        href="https://sql-runner.brainybaba.com/"
      >sql-runner.brainybaba.com</a>
    </h3>
    <h3>
      <a
        target="_blank"
        href="https://spider.brainybaba.com/"
      >spider.brainybaba.com</a>
    </h3>
    <h3>Improve Addition</h3>
    <ul>
      <li>
        <a
          href="/addition/10x10"
          rel="noopener"
        >10 x 10</a>
      </li>
      <li>
        <a
          href="/addition/addGame"
          rel="noopener"
        >Gamification</a>
      </li>
    </ul>
    <h3>Improve Squares</h3>
    <ul>
      <li>
        <a
          href="/squares/squareGame"
          rel="noopener"
        >Square Game</a>
      </li>
      <li>
        <a
          href="/squares/squareInputGame"
          rel="noopener"
        >Square Input</a>
      </li>
    </ul>
    <h3>Data Visualisation</h3>
    <ul>
      <li>
        <a href="/dataVisualisation">Visualisations</a>
      </li>
    </ul>
    <h3>SVG Samples</h3>
    <ul>
      <li>
        <a href="/svgTrials">svg Trials</a>
      </li>
      <li>
        <a href="/svgAnimate">svg Animated</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: {
      type: String,
      default: ""
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a,
a:not([href]) {
  color: #42b983;
  cursor: pointer;
}
a:not([href]):hover {
  color: #42b983;
  cursor: pointer;
  text-decoration: underline;
}
</style>
