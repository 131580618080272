import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import About from "./views/About.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/svgTrials",
      name: "svgTrials",
      component: () => import("@/views/svgLearn/svgTest.vue")
    },
    {
      path: "/svgAnimate",
      name: "svgAnimate",
      component: () => import("@/views/svgLearn/svgAnimate.vue")
    },
    {
      path: "/dataVisualisation",
      name: "dataVisualisation",
      component: () => import("@/views/dataVisualisation/DataVisualisation.vue")
    },
    {
      path: "/addition/10x10",
      name: "10x10Adddition",
      component: () => import("@/views/addition/10x10.vue")
    },
    {
      path: "/addition/addGame",
      name: "addGame",
      component: () => import("@/views/addition/AddGame.vue")
    },
    {
      path: "/squares/squareGame",
      name: "squareGame",
      component: () => import("@/views/squares/squareGame.vue")
    },
    {
      path: "/squares/squareInputGame",
      name: "squareInputGame",
      component: () => import("@/views/squares/squareInputGame.vue")
    },
    {
      path: "/privacy_policy",
      name: "privacyPolicy",
      component: () => import("@/views/PrivacyPolicy.vue")
    },
    {
      path: "/term_and_conditions",
      name: "tandc",
      component: () => import("@/views/TAndC.vue")
    },
    {
      path: "/color-ball-sort/privacy_policy",
      name: "color-ball-sort-privacy-policy",
      component: () => import("@/views/colorBallSort/PrivacyPolicy.vue")
    },
  ]
});
